<template>
    
    <v-row dense>

        <!--Обертка для секции-->
        <v-col cols="12" sm="12" md="12" class="section-wrapper">
            
            <!--Блок Обращения заявителя-->
            <v-card flat>
                <v-card-text>
                    <div class="form-box-title">{{ $t("Обращения_заявителя") }}</div>

                    <v-data-table
                        :loading="loading"
                        :headers="headers"
                        :items="entities"
                        :item-class="itemRowBackground"
                        :options.sync="options"
                        :server-items-length="total"
                        :footer-props="{ disableItemsPerPage: true, itemsPerPageText: '', itemsPerPageOptions: [] }"
                        class="elevation-1 customization-list-table sticky-table hidden-items-per-page"
                        :show-select="document.RegState != 1 && document.RegState != 4"
                        single-select
                        v-model="selectedStatement"
                        checkbox-color="primary"
                        @dblclick:row="(event, data) => $eventBus.$emit('open-document', { id: data.item.id, type: 'CitizenStatements.EuolStatement' })">
                        <v-progress-linear slot="progress" height="2" color="#E43958" indeterminate></v-progress-linear>

                        <template v-slot:[`item.regdate`]="{ item }">
                            <div>{{ $moment(item.regdate).format('DD.MM.YYYY') }}</div>
                        </template>

                        <template v-slot:[`item.declarant`]="{ item }">
                            <div class="etw-text-container">
                                <div class="etw-text" v-tooltip.top-center="item.declarant">
                                    {{item.declarant}}
                                </div>
                            </div>
                        </template>

                        <template v-slot:[`item.annotation`]="{ item }">
                            <div class="elipsis-text-wrap">
                                <v-chip
                                    v-if="item.ordernumber > 0"
                                    class="table-label sendedstatus"                            
                                    small
                                    label                            
                                    :outlined="!item.sendedtoaikyn"
                                    :color="item.sendedtoaikyn ? 'var(--d-green)' : '' "   
                                    :text-color="item.sendedtoaikyn ? 'white' : ''"
                                >
                                    <div>{{$t("Версия")}} {{item.ordernumber}}</div>
                                </v-chip>
                                <div class="etw-text-container">
                                    <div class="etw-text" v-tooltip.top-center="item.annotation">
                                        {{item.annotation}}
                                    </div>
                                </div>
                            </div>
                        </template>

                        <template v-slot:[`item.controldate`]="{ item }">
                            <div>{{ $moment(item.controldate).format('DD.MM.YYYY') }}</div>
                        </template>
                        
                        <template v-slot:[`item.answerstatus`]="{ item }">
                            <div class="elipsis-text-wrap">
                                <StatusChip :status="getEuolExecutionStatus(item)"/>
                                <StatusChip :status="getEuolAnswerStatus(item)"/>                                    
                            </div>
                        </template>

                    </v-data-table>

                </v-card-text>
            </v-card>

        </v-col>

    </v-row>
    
</template>

<script>
import i18n from '@/i18n'
import { mapGetters } from 'vuex';
import Axios from "axios";
import { httpAPI } from "@/api/httpAPI";
import StatusChip from "@/components/StatusChip"

export default {
    columnIndex:  {
        'regnumber': 2,
        'regdate': 3,
        'uniquenumber': 4,
        'controldate': 5,
        'declarant': 6,
        'annotation': 14
    },
    name: "EuolSimilar",
    components: {
        StatusChip
    },
    computed: {
        ...mapGetters('actionsource', { dataSource: 'getDataSource' }),
        document() {
            return this.dataSource.Data.Object.Document;
        },
        filter () {
            return {
                ...this.options,
                PerCategory: this.document.Card.PerCategory,
                SearchStr: this.getConcatedName(),
                ExcludeById: this.document.id
            }
        },
        avaibleStatuses() {
            return this.$store.getters['references/GetAvaibleStatuses'];
        },
        selectedStatement: {
            get: function() {
                return [this.$store.getters['dialogs/registerEuolStatementAs/getOrigin']];
            },
            set: function(value) {
                this.$store.commit('dialogs/registerEuolStatementAs/SET_ORIGIN', value.length > 0 ? value[0] : null);
            }
        }
    },
    data() {
        return {
            loading: true,
            cancellationTokenSorce: null,
            headers: [
                { text: i18n.t("Номер"), value: 'regnumber', width: '150px' },
                { text: i18n.t("Рег._дата"), value: 'regdate', width: '110px' },
                { text: i18n.t("Обратившееся_лицо"), value: 'declarant', width: '15%' },
                { text: i18n.t("Краткое_содержание"), value: 'annotation' },
                { text: i18n.t("Уникальный_номер"), value: 'uniquenumber', width: '160px' },
                { text: i18n.t("Срок"), value: 'controldate', width: '110px' },
                { text: i18n.t("Статус"), value: 'answerstatus', width: '180px' },
            ],
            total: 0,            
            entities: [],
            options: {
                page: 1,
                itemsPerPage : 10,
                sortBy : [ "regdate" ],
                sortDesc : [ true ],
                groupBy : [],
                groupDesc : [],
                multiSort : false,
                mustSort : false
            },
        }
    },
    methods: {
        getEuolAnswerStatus(source) {            
            var key = this.$helpers.getEuolAnswerStatus(source);
            return this.avaibleStatuses[key];
        },
        getEuolExecutionStatus(source) {
            var key = this.$helpers.getEuolExecutionStatus(source, this.currentTimestamp);
            return this.avaibleStatuses[key];
        },
        itemRowBackground (item) {
            return item.readed ? '' : 'font-weight-bold';
        },
        async getData () {

            this.$store.commit('dialogs/registerEuolStatementAs/SET_ORIGIN', null);

            this.total = 0;
            this.entities = [];
            this.loading = true;

            if (this.cancellationTokenSorce)
                this.cancellationTokenSorce.cancel('New request started');

            this.cancellationTokenSorce = Axios.CancelToken.source();

            let response = await httpAPI({
                url: `api/correspondence/list?collection=EuolStatements&filter=${this.getSerializedFilter()}`,
                method: 'GET',
                cancelToken: this.cancellationTokenSorce.token,
            });

            if (response) {
                this.currentTimestamp = response.data?.payload?.currentTimestamp;
                this.entities = response.data?.payload?.data ?? [];
                this.total = response.data?.payload?.total ?? 0;
            }
            
            this.loading = false;
        },
        getSerializedFilter() {
            let { sortBy, sortDesc, page, itemsPerPage, PerCategory, SearchStr, ExcludeById } = this.filter;
            let OrderDir = sortDesc.length <= 0 ? false : sortDesc[0];
            let sortColumn = sortBy.length <= 0 ? "regdate" : sortBy[0];
            let OrderBy = this.$options.columnIndex[sortColumn] ?? 3;

            return JSON.stringify(
            {
                RegState: 1,
                PerCategory,
                SearchStr,
                IsGovService: "0",
                OrderBy,
                OrderDir,
                Limit: itemsPerPage,
                Offset: (page - 1) * itemsPerPage,
                ExcludeById
            });
        },
        getConcatedName() {

            if (this.document.Card.PerCategory === 2)
                return this.document.Card.PerEnterpriseName.trim();
            
            let result = "";

            if (this.document.Card.PerFirstName)
                result = this.document.Card.PerFirstName.trim();

            if (this.document.Card.PerSecondName)
                result += ` ${this.document.Card.PerSecondName.trim()}`;

            if (this.document.Card.PerMiddleName)
                result += ` ${this.document.Card.PerMiddleName.trim()}`;

            return result;
        },
    },
    watch: {
        filter: {
            handler () {
                this.getData()
            },
            deep: true,
        },
    }
} 
</script>

<style scoped>
.hidden-items-per-page >>> .v-data-footer {
    justify-content: flex-end;
    min-height: 43px;
}
.hidden-items-per-page >>> .v-data-table__selected{
    background: #e5f5fb!important;
}
</style>